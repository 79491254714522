<template>
  <a
    :href="socialPath"
    :data-social="buttonName"
    :data-social-path="socialPath"
    :class="className"
    :data-social-href="socialHref"
  >
    <span :class="$style.icon">
      <base-icon :type="iconName" />
    </span>
    <slot />
  </a>
</template>

<script>
import BaseIcon from '@/components/icon.vue';

export default {
  name: 'SocialButton',
  components: {
    BaseIcon
  },
  props: {
    method: {
      type: Object,
      required: true
    },
    oauth: {
      type: Boolean,
      required: false
    },
    connecting: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    className() {
      return [
        this.$style[this.buttonName],
        this.oauth
          ? [this.$style.small, this.$style.singleIcon]
          : [this.$style.btn, this.$style.full],
        this.connecting ? [this.$style.btn_preload, this.$style[`${this.buttonName}_preload`]] : ''
      ];
    },
    socialHref() {
      return this.method.href ? this.method.href : '/';
    },
    socialPath() {
      return this.method.connect_settings.login_path;
    },
    iconName() {
      return `login-${this.buttonName}`;
    },
    buttonName() {
      return this.method.id.toLocaleLowerCase();
    }
  }
};
</script>

<style module lang="less">
@import '~@less/blocks/button';
@import '~@less/common/mixins/font';
@import '~@less/common/mixins/input';

@socialPaddingLeft: 56px;
@socialPaddingRight: 10px;

@btnSj: #00aa87;
@btnSjActive: #009977;

@btnLi: #0075bb;
@btnLiActive: #0069a7;

@btnGoogle: #4285f4;
@btnGoogleActive: darken(#4285f4, 10%);

@btnVk: #4d75a3;
@btnVkActive: darken(#4d75a3, 10%);

@btnMk: #759aa8;
@btnMkActive: #dddddd;

@btnZpru: #f1ba36;
@btnZpruActive: #d29b18;

@btnAh: #eaeaea;
@btnAhActive: #cccccc;

@btnYandex: #000;

@defaultBlack: #38343f;
@defaultBlackActive: darken(#38343f, 10%);

.social(@social, @color, @colorActive, @iconWidth, @iconHeight, @textColor: #fff, @iconColor: @color) {
  .@{social} {
    background-color: @color;
    border: 1px solid @color;
    color: @textColor;

    &:active,
    &:active:hover {
      background-color: @colorActive;
    }
  }

  .@{social} .icon svg {
    width: unit(@iconWidth, px);
    height: unit(@iconHeight, px);
  }

  .@{social}&_preload,
  .@{social}&_preload:hover,
  .@{social}&_preload:active {
    background-color: @color;
  }

  .@{social}&_connected {
    .font_regular();

    color: @color;

    &:hover {
      border-color: @color;
    }

    &:active,
    &:active:hover {
      border-color: @color;
    }
  }

  .@{social}&_connected .icon {
    background-color: @iconColor;
  }
}

.btn {
  .base-button();
  .font_bold();

  text-transform: none;
  font-size: @baseFontSize + 2px;
  line-height: @baseLineHeight;
  padding: @inputBigVerticalPadding @socialPaddingRight @inputBigVerticalPadding @socialPaddingLeft;
  overflow: hidden;
  background-color: @defaultBlack;
  color: white;
  text-align: left;
  border: 1px solid @defaultBlack;

  :before {
    content: '';
    position: absolute;
    left: 42px;
    top: 0;
    border-right: 1px solid rgba(255, 255, 255, 0.5);
    height: 38px;
    width: 1px;
  }

  &_hover,
  &:hover {
    border-color: @hoverBorderColor;
  }

  &:active,
  &:active:hover {
    .btn-box-shadow();

    padding-top: @inputBigVerticalPadding + 1px;
    padding-bottom: @inputBigVerticalPadding - 1px;
    background-color: @defaultBlackActive;
    border-color: @hoverBorderColor;
  }

  &:active &__icon,
  &:active:hover &__icon {
    top: 1px;
  }

  &_google:before {
    border-right: 1px solid @btnGoogle;
  }

  &_google&_connected {
    .font_regular();

    color: rgba(#000, 0.4);
  }

  &.google .icon {
    background-color: white;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }

  &_preload,
  &_preload:hover,
  &_preload:active {
    .button-preload();

    padding-top: @inputBigVerticalPadding + 1px;
    padding-bottom: @inputBigVerticalPadding - 1px;
  }

  &_preload &__icon {
    top: 1px;
  }
}

.singleIcon {
  border-radius: 3px;
  height: 40px;
  border: none;
  position: relative;
  line-height: 22px;
  outline: 0 none;
  display: inline-block;
  text-decoration: none;
  text-align: left;

  .auth-button__icon {
    width: 100%;
    height: 100%;
  }

  .icon {
    width: 100%;
  }
}

.small {
  background-color: white;
  width: 50px;
  padding-left: 0;

  &:before {
    border-right: none;
  }

  &.google {
    background-color: #fff;
  }
}

.full {
  width: 310px;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 44px;
  height: 38px;

  svg {
    height: 100%;
    width: 100%;
  }
}

.social(hh, @btnRed, @btnRedActive, 19, 19);
.social(sj, @btnSj, @btnSjActive, 14, 16);
.social(li, @btnLi, @btnLiActive, 17, 17);
.social(google, @btnGoogle, @btnGoogle, 17, 18, #fff, #fff);
.social(vk, @btnVk, @btnVkActive, 20, 12);
.social(mk, @btnMk, @btnMkActive, 34, 34);
.social(zpruhh, @btnZpru, @btnZpruActive, 15, 18, #000);
.social(ah, @btnAh, @btnAhActive, 22, 22, #38343f, #fff);
.social(yandex_oauth2, @btnYandex, @btnYandex, 21, 21);

.yandex_oauth2_preload,
.yandex_oauth2_preload:hover,
.yandex_oauth2_preload:active {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAB+SURBVHgBrdTLDYAgEARQhoMWYA/2X5INWAMyHAwxAssykxB+yctyYBGqpJT2PJ15bMGWC8BdH0CJvaAKK6ASY6ISY3FRibG4GIQYi7OAZoz7ETiFjcBprAe6sBboxv7AJewLLmM1KMEY/uVDhRWwd+lqHFIsvxRKjAsoMeYBEuRlYI/BYCgAAAAASUVORK5CYII=);
}
</style>

<i18n lang="json">{}</i18n>
