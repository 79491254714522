// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import buildUrl from 'axios/lib/helpers/buildURL';
import { ApiLayer } from '@/api/utils/api-layer';
import axios, { AxiosClient } from '@/api/utils/client/axios';
import { ItemList } from '@/api/utils/types';
import { CancelablePromise } from '@/api/utils/cancelable-promise';

export enum ConnectType {
  oauth2 = 'OAUTH2',
  telegram = 'TELEGRAM'
}

export enum AuthType {
  sj = 'SJ',
  hh = 'HH',
  li = 'LI',
  vk = 'VK',
  mk = 'MK',
  zpru = 'ZPRU',
  zpruhh = 'ZPRUHH',
  google = 'GOOGLE',
  avito = 'AVITO',
  ah = 'AH',
  gmate = 'GMATE',
  telegram = 'TELEGRAM',
  podbor = 'PODBOR',
  rabotaru = 'RABOTARU',
  zoom = 'ZOOM',
  outlook365 = 'OUTLOOK365'
}

export enum IntegrationType {
  conferences = 'conferences',
  job_boards = 'job_boards',
  ims = 'ims',
  calendars = 'calendars',
  emails = 'emails'
}

interface Profile {
  displayName: string;
  first_name: string;
  last_name: string;
  phone: string;
}

export interface Auth {
  id: number;
  profile: Profile;
}

type Integration = {
  auth: Auth | null;
  available: boolean;
  calendar: boolean;
  conference: boolean;
  connect_settings: Record<string, string>;
  connect_type: ConnectType;
  email: boolean;
  href: string;
  id: AuthType;
  im: boolean;
  job_board: boolean;
  name: string;
};

export enum Statuses {
  LOGGED_IN = 'logged_in',
  MERGE = 'merge'
}

export enum ErrorTypes {
  LOGIN = 'login_error',
  ANOTHER_ACCOUNT = 'another_account',
  ONLY_CORPORATE_ACCOUNTS = 'only_corporate_accounts',
  ACCOUNT_NOT_SUPPORTS_TEAMS = 'conference.teams.account_not_supports_conference_api',
  ACCOUNT_NOT_SUPPORTS_YANDEX_TELEMOST = 'conference.yandex_telemost.account_not_supports_conference_api'
}

export function isKnowMessage(message: ErrorTypes | Statuses): boolean {
  return isErrorMessage(message as ErrorTypes) || isStatusMessage(message as Statuses);
}

function isErrorMessage(message: ErrorTypes): boolean {
  return Object.values(ErrorTypes).includes(message);
}

function isStatusMessage(message: Statuses): boolean {
  return Object.values(Statuses).includes(message);
}

interface OauthPayload {
  path: string;
  href?: string;
  forceLogin?: boolean;
}

interface NativePayload {
  code: string | null;
  password: string | null;
  phone: string;
}

class IntegrationLayer extends ApiLayer<AxiosClient> {
  constructor(client: AxiosClient) {
    super(client);
    this.getIntegrationsByType = this.memoizeMethod(this.getIntegrationsByType);
    this.getTelegram = this.memoizeMethod(this.getTelegram);
  }

  getIntegrationsByType(integrationType: IntegrationType) {
    return this.methods
      .get<ItemList<Integration>>(`/settings/integrations/${integrationType}`, {
        baseURL: '/'
      })
      .then((res) => res.items);
  }

  getTelegram() {
    return this.getIntegrationsByType(IntegrationType.ims).then((integrations) =>
      integrations.find(({ id }) => id === AuthType.telegram)
    );
  }

  oauth2Connect({ path, href = '/', forceLogin = false }: OauthPayload) {
    const url = buildUrl(path, {
      force_login: forceLogin
    });
    const popup = window.open(
      url,
      'Huntflow',
      'width=700,height=600,resizable=yes,scrollbars=yes,status=yes'
    );

    let promiseResolve: (value?: unknown) => void, promiseReject: (reject?: unknown) => void;

    // проще использовать CancelablePromise.resolve()
    const promise = new CancelablePromise(
      new Promise((resolve, reject) => {
        promiseResolve = resolve;
        promiseReject = reject;
      }),
      () => {
        popup?.close();
      }
    );

    const timer = setInterval(function () {
      if (popup == null || popup.closed) {
        promiseReject();
        window.removeEventListener('message', messageHandler);
        clearInterval(timer);
      }
    }, 1000);

    function messageHandler(e: MessageEvent) {
      if (window.location.origin !== e.origin || !isKnowMessage(e.data)) {
        return;
      }

      if (e.data === Statuses.LOGGED_IN) {
        window.location.reload();

        return promiseResolve();
      }

      if (e.data === Statuses.MERGE) {
        window.location.href = '/account/merge';

        window.removeEventListener('message', messageHandler);
        return promiseResolve();
      }

      if (isErrorMessage(e.data)) {
        return promiseReject(e);
      }

      promiseResolve(e);
    }

    window.addEventListener('message', messageHandler);

    return promise;
  }

  nativeConnect(path: string, data: NativePayload) {
    return this.methods.post<Auth>(path, data, {
      baseURL: ''
    });
  }

  disconnect(authId: number) {
    return this.methods.delete(`/settings/social/disconnect/${authId}`, {
      baseURL: ''
    });
  }
}
// TODO: переделать на именованный экспорт, в соответствии с README
export default new IntegrationLayer(axios);
